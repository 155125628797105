import { LOGIN_SUCCESS, LOGOUT_SUCCESS, REMEMBERME } from "./actionTypes";


export const loginSuccess = (userData) => ({
    type: LOGIN_SUCCESS,
    payload: userData,
});

export const logoutSuccess = (userData) => ({
    type: LOGOUT_SUCCESS,
});


