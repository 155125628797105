// React Imports
import { Fragment, useEffect, useState } from 'react';

// MUI Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled, useTheme } from '@mui/material/styles';
import MuiCard from '@mui/material/Card';
import InputAdornment from '@mui/material/InputAdornment';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

// Icons Imports
import EyeOutline from 'mdi-material-ui/EyeOutline';
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline';

// Images imports
import tree1 from '../../dates/imgs/auth-v1-tree.png';
import tree2 from '../../dates/imgs/auth-v1-tree-2.png';
import lightTheme from '../../dates/imgs/auth-v1-mask-light.png';
import logo from '../../dates/imgs/vy.png'
import axios from 'axios';
import { API_URL } from '../../config/Api';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { FaInfoCircle } from "react-icons/fa";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// Redux imports and exports
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../../Redux/actions';

// Data encrypt and decrypt
import { decryptData, encryptData } from '../../utils/cryptoUtils';

// Styled Components
const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { width: '28rem' }
}));

const LinkStyled = styled('a')(({ theme }) => ({
  fontSize: '0.875rem',
  textDecoration: 'none',
  color: '#9155FD'
}));

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '0.875rem',
    color: '#9155FD',
  }
}));


const LoginPage = (props) => {
  const [values, setValues] = useState({
    password: '',
    showPassword: false
  });
  const [emailValue, setEmailValue] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    const encryptedDatas = localStorage.getItem('encryptedData');
    const decryptedData = decryptData(encryptedDatas);
    const emailData = decryptedData?.email;
    const passwordData = decryptedData?.password;
    setEmailValue(emailData !== null ? emailData : '');
    setValues({ password: passwordData !== null ? passwordData : '' })
  }, [])

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleEmailValue = (event) => {
    setEmailValue(event.target.value);
  }

  const verifyUserDetails = () => {
    let value = {
      email: emailValue,
      password: values.password
    };

    if (emailValue !== '' && values.password !== '') {
      axios.post(`${API_URL}/admin/login`, value)
        .then(response => {
          const token = response;
          dispatch(loginSuccess(token));
          navigate('/admin-dashboard/sales');
          setEmailValue('');
          setValues({ password: '' })
        })
        .catch(error => {
          console.error('Login Error authenticating user:', error);
          toast.info(error.response.data.message, {
            progressStyle: { background: '#9155FD' },
            icon: <FaInfoCircle style={{ color: '#9155FD' }} />,
            theme: 'light',
          });
        });
    } else {
      toast.info("Please Enter Valid Credentials...", {
        progressStyle: { background: '#9155FD' },
        icon: <FaInfoCircle style={{ color: '#9155FD' }} />,
        theme: 'light',
      });
    }
  }

  const rememberMeData = () => {
    let value = {
      email: emailValue,
      password: values.password,
      check: true,
    };
    const encryptedData = encryptData(value);
    localStorage.setItem('encryptedData', encryptedData);
  }

  const style = {
    "& label.Mui-focused": {
      color: "#9155FD"
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#9155FD",
        color: 'red'
      }
    },
    marginBottom: 4,
  }
  const filed = {
    "& label.Mui-focused": {
      color: "#9155FD"
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#9155FD",
      }
    },
  }

  const loginBtn = {
    '&:hover': {
      backgroundColor: 'rgb(129,66,245)'
    },
    backgroundColor: '#9155FD'
  }

  return (
    <Box sx={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center', padding: theme.spacing(5) }}>
      <ToastContainer />
      <Card sx={{ zIndex: 1, }}>
        <CardContent sx={{ padding: theme => `${theme.spacing(6, 4, 4)} !important` }}>
          <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography>
              <img src={logo} alt='img' />
            </Typography>
          </Box>
          <Box sx={{ mb: 4 }}>
            <Typography variant='h5' sx={{ fontWeight: 600, marginBottom: 1.5 }}>
              Welcome to Web-App! 👋🏻
            </Typography>
            <Typography variant='body2'>Please sign-in to your account</Typography>
          </Box>
          <form noValidate autoComplete='off' onSubmit={e => e.preventDefault()}>
            <FormControl fullWidth sx={filed}>
              <InputLabel htmlFor='auth-login-email'>Email</InputLabel>
              <OutlinedInput
                label='Email'
                value={emailValue}
                sx={style}
                id='auth-login-email'
                onChange={(e) => handleEmailValue(e)}
                type={values.showPassword ? 'text' : 'email'}
              />
            </FormControl>
            <FormControl fullWidth sx={filed}>
              <InputLabel htmlFor='auth-login-password'>Password</InputLabel>
              <OutlinedInput
                label='Password'
                value={values.password}
                id='auth-login-password'
                onChange={handleChange('password')}
                type={values.showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      edge='end'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      aria-label='toggle password visibility'
                    >
                      {values.showPassword ? <EyeOutline /> : <EyeOffOutline />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Box
              sx={{ mb: 4, display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <FormControlLabel
                control={<Checkbox style={{ color: '#9155FD' }}
                  onClick={() => rememberMeData()} />}
                label='Remember Me' />
              <LinkStyled href='#' onClick={e => e.preventDefault()}>
                Forgot Password?
              </LinkStyled>
            </Box>
            <Button fullWidth size='large' variant='contained' sx={loginBtn} onClick={() => verifyUserDetails()}>
              Login
            </Button>
          </form>
        </CardContent>
      </Card>
      <div style={{ bottom: 0, zIndex: -1, width: '100%', height: '100%', position: 'absolute', background: 'rgb(245, 245, 247)' }} />
      <Fragment>
        <img src={tree1} alt='img' style={{ left: 0, bottom: 0, position: 'absolute' }} />
        <img src={lightTheme} alt='img' style={{ bottom: 0, zIndex: -1, width: '100%', position: 'absolute' }} />
        <img src={tree2} alt='img' style={{ right: 0, bottom: 0, position: 'absolute' }} />
      </Fragment>
    </Box>
  );
};

export default LoginPage;