import { Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/es/integration/react'
import store, { persistor } from "./Redux/store";
import AdminDashboard from "./pages/admin-dashboard/adminDashboard";
import LoginPage from "./components/Login/LoginPage";
import Error404 from "./components/ErrorPages/Error404";

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <div className=" h-[100vh] w-full">
                    <Routes>
                        <Route exact path="/" Component={LoginPage} />
                        <Route path="/admin-dashboard/:category" Component={AdminDashboard} />
                        <Route path="*" Component={Error404} />
                    </Routes>
                </div>
            </PersistGate>
        </Provider>
    );
}

export default App;