
import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../../config/Api";
import { useSelector } from "react-redux";
import TablePagination from '@mui/material/TablePagination';
import * as React from 'react';
import './TableManagement.css'
// ** MUI Imports
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Checkbox from '@mui/material/Checkbox';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import Magnify from 'mdi-material-ui/Magnify'
//icons
import { BsFillPencilFill } from "react-icons/bs";
import { ImBin } from "react-icons/im";
import Skeleton from '@mui/material/Skeleton';
import TableDetails from "./TableDetails";
import { BsFilter } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";

import { Box, Button, Input, InputAdornment, ListItemText, OutlinedInput, TextField } from "@mui/material";

export function TableManagement() {
    // ** States
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loader, setLoader] = useState(true);
    const [tableId, setTableId] = useState('');
    const [tableVisible, setTableVisible] = useState(false);
    const [selected, setSelected] = React.useState([]);  //for select all checkbox
    const [objectKey, setobjectKey] = useState([]);
    const [objectValue, setobjectValue] = useState([]);
    const [search, setSearch] = useState('');
    const [filterBox, setFilterBox] = useState(false);
    const [filterName, setFilterName] = useState('id');
    const [filterSearch, setFilterSearch] = useState('');
    const [filterData, setFilterData] = useState({ 'name': '', "id": '' })


    const [data, setData] = useState([]);
    const userData = useSelector(state => state.user);


    useEffect(() => {
        axios.get(`${API_URL}/content-manager/explorer/application::restaurantrooms.restaurantrooms/?_limit=10&_sort=id%3AASC&_start=0`, {
            headers: {
                Authorization: `Bearer ${userData.data.data.token}`
            }
        })
            .then((response) => {
                const responseData = response.data;
                setData(responseData);
                setLoader(false);
            })
            .catch((error) => {
                console.log('RestuarantsEroor', error);
            })
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const searchRestuarant = (row) => {
        const verify = Object.values(row).some(value => typeof value === 'string' && value.toLowerCase().includes(search.toLowerCase()));
        const values = verify ? true : false;
        return values;
    }

    const applyFilterDetails = () => {
        setFilterData({ 'name': filterSearch, 'id': filterName })
        setFilterBox(false);
        setFilterName('id');
        setFilterSearch('');
        Object.entries(data).map((er) => (
            Object.entries(er[1]).map((et) => {
                if (et[0] === filterName && et[1] === filterSearch) {
                    setData([er[1]]);
                }
                // else {
                //     setData([])
                // }
            })
        ))
    }

    return (
        <>
            {!tableVisible ?
                loader ?
                    <Skeleton animation="wave" height={'45vh'} variant="rectangular" />
                    :
                    <Box className='mainPage'>
                        <div>
                            <TextField
                                size='small'
                                placeholder="Search ..."
                                onChange={(e) => setSearch(e.target.value)}
                                // onChange={(e) => searchRestuarant(e)}
                                sx={{ '& .MuiOutlinedInput-root': { borderRadius: 4 } }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <Magnify fontSize='small' />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                        <Box className="addNewRestuarantBtn">
                            <div style={{ flexDirection: 'column', display: 'flex' }}>
                                <text className="mainHeading">RestuarantRooms</text>
                                <text className="mainSubHeading">{`${data.length} Results`}</text>
                            </div>
                            <div>
                                <Button color="primary" variant="contained"
                                    onClick={() => alert('Inprogress')}
                                    startIcon={<AiOutlinePlus />}>
                                    Add New RestuarantRooms
                                </Button>
                            </div>
                        </Box>
                        {filterBox &&
                            <Paper className="addNewRestuarantBtn">
                                <Box style={{ flexDirection: 'row', height: 35, display: 'flex' }}>
                                    <Select value={filterName} fullWidth className="inputFiledStyle" onChange={(e) => setFilterName(e.target.value)}>
                                        <MenuItem value='id'><em>Id</em></MenuItem>
                                        <MenuItem value='name'>Name</MenuItem>
                                        <MenuItem value='createdAt'>CreatedAt</MenuItem>
                                        <MenuItem value='updatedAt'>UpdatedAt</MenuItem>
                                    </Select>
                                    <AiOutlinePlus size={35} fontWeight={600} style={{ marginRight: 5 }} />
                                    <Input fullWidth placeholder="Search..." className="inputFiledStyle" value={filterSearch} onChange={(e) => setFilterSearch(e.target.value)} />
                                </Box>

                                <Box style={{ flexDirection: 'row', height: 35, display: 'flex' }}>
                                    <Button color="primary" variant="contained"
                                        fullWidth
                                        sx={{ mr: 2 }}
                                        onClick={() => { setFilterName('id'); setFilterSearch(''); setFilterBox(false) }}
                                        startIcon={<AiOutlinePlus />}>
                                        Clear
                                    </Button>
                                    <Button color="primary" variant="contained"
                                        fullWidth
                                        onClick={() => filterSearch != '' && applyFilterDetails()}
                                        startIcon={<AiOutlinePlus />}>
                                        Apply
                                    </Button>
                                </Box>
                            </Paper>}
                        <Box className="rowData">
                            <Button variant="outlined"
                                onClick={() => setFilterBox(!filterBox)}
                                startIcon={<BsFilter size={12} />}
                            >
                                Filter
                            </Button>
                            {filterData.name !== '' && <Box className="filterResultBox">
                                <text style={{ fontSize: 15, marginRight: 5 }}>{`${filterData.id} ${filterData.name}  `}</text>
                                <AiOutlineClose size={19} onClick={() => { setFilterData({ 'name': '', 'id': '' }); window.location.reload(); }} />
                            </Box>}
                        </Box>
                        <Paper sx={{ overflow: 'hidden', marginTop: 1, borderWidth: 1, borderColor: 'blanchedalmond' }}>
                            <TableContainer>
                                <Table stickyHeader aria-label='sticky table'>
                                    <TableHead>
                                        <TableRow key={data._id}>
                                            <TableCell align='left' className="table-Heading" >
                                                <Checkbox
                                                    onClick={(event) => handleSelectAllClick(event)} />
                                                Id
                                            </TableCell>
                                            <TableCell align='left' className="table-Heading" >
                                                Name
                                            </TableCell>
                                            <TableCell align='left' className="table-Heading" >
                                                CreatedAt
                                            </TableCell>
                                            <TableCell align='left' className="table-Heading" >
                                                UpdatedAt
                                            </TableCell>
                                            <TableCell /><TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => {
                                            const isItemSelected = isSelected(row.id);
                                            return (searchRestuarant(row) &&
                                                <TableRow key={row.id} onClick={() => { setTableVisible(true); setTableId(row._id) }}>
                                                    <TableCell>
                                                        <Checkbox
                                                            key={row.id}
                                                            tabIndex={-1}
                                                            checked={isItemSelected}
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={(event) => handleClick(event, row.id)}
                                                        />
                                                        {row._id}
                                                    </TableCell>
                                                    <TableCell>{row.name}</TableCell>
                                                    <TableCell>{row.createdAt}</TableCell>
                                                    <TableCell>{row.updatedAt}</TableCell>
                                                    <TableCell><BsFillPencilFill /></TableCell>
                                                    <TableCell><ImBin /></TableCell>
                                                </TableRow>
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                component='div'
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box >
                :
                <TableDetails
                    id={tableId}
                    backMenu={() => setTableVisible(false)}
                />
            }
        </>
    )
}




