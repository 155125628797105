import { useParams } from "react-router-dom"
import Main from "../admin-main/main"
import Marketing from "../admin-marketing/marketing"
import { TableManagement } from "../TableManagement/TableManagement"
import Error404 from "../ErrorPages/Error404"


export default function NavigationFiles() {
    const { category } = useParams()
    return (
        <div style={{ height: '100vh' }}>
            {
                category === 'sales' && <Main />
            }
            {
                category === 'market' && <Marketing />
            }
            {
                category === 'tableManagement' && <TableManagement />  //restuarantRooms
            }
            {
                category === 'couponsManagement' && <Error404 />
            }
            {
                category === 'inventoryManagement' && <Error404 />
            }
            {
                category === 'restuarantInformation' && <Error404 />
            }
        </div>
    )
}