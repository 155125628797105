import { legacy_createStore } from "redux";
import rootReducer from "./reducer";
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = legacy_createStore(persistedReducer,
    // window.__REDUX_DEVTOOLS_EXTENSION__()          //uncomment for redux debug before install in your device redux devtools
);

export const persistor = persistStore(store);

export default store;