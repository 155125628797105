import { combineReducers } from "redux";


const initialState = {
    user: null,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return action.payload;
        case 'LOGOUT_SUCCESS':
            return null;
        default:
            return state;
    }
};

const rootReducer = combineReducers({
    user: userReducer,
});

export default rootReducer;