//This file mainly we are using remember me functionality
import CryptoJS from 'crypto-js';

const SECRET_KEY = 'T#3s$2q^P@5n&H!8'; // This is our sample secret key

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

export const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    // Check if decryption succeeded
    if (bytes.sigBytes === 0) {
      throw new Error('Decryption failed: Invalid data or incorrect secret key');
    }
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error('Error decrypting data:', error.message);
    // Handle the error appropriately, such as returning a default value or throwing a custom error
    // For example, you can return null or an empty object/array
    return null;
  }
};
