import * as React from 'react';

import { Backdrop, Box, Button, Dialog, FormControl, FormLabel, ListItemText, MenuItem, Select, Switch, TextField, css } from "@mui/material";
import { isValidElement, useEffect, useState } from "react";
import { API_URL } from "../../config/Api";
import { useSelector } from "react-redux";
import axios from "axios";
// ** MUI Imports
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';

//icons
import { BsFillPencilFill } from "react-icons/bs";
import { ImBin } from "react-icons/im";
import { AiOutlineArrowLeft } from "react-icons/ai";



export default function TableDetails(props) {
    const [rooms, setRooms] = useState([]);
    const [roomDetails, setRoomDetails] = useState([]);
    const [editModal, setEditModal] = useState(false);
    const [tableDetails, setTableDetails] = useState([]);
    const [headingData, setHeadingData] = useState({});
    const [restuarantLists, setRestuarantLists] = useState([]);
    const [restuarant, setRestuarant] = useState('');

    const userData = useSelector(state => state.user);
    useEffect(() => {
        const token = userData.data.data.token;
        const api = `${API_URL}/content-manager/explorer/application::restaurantrooms.restaurantrooms/${props.id}`
        axios.get(api, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((response) => {
                const details = response.data
                setRooms(details.rooms[0]);
                setRoomDetails({
                    'id': details._id,
                    'name': details.name,
                })
                setHeadingData(rooms != null && rooms != undefined ? arrangeData() : verifyData())
            })
            .catch((error) => {
                console.error('TableDetailsError', error)
            })
        restuarantList();
    }, [editModal]);

    const verifyData = () => {
        const dateString = new Date();
        const dateObject = dateString.toISOString();
        let roomNullData = {
            'name': 'general',
            'roomId': 1,
            'tables': {
                'id': 1,
                'name': 'T1',
                'type': 'indoor',
                'booked': false,
                'chairCount': 4,
                'booked_from': dateObject,
                'booked_to': dateObject
            }
        };
        return roomNullData.tables;

    }

    const restuarantList = () => {
        const token = userData.data.data.token;
        const api = `${API_URL}/content-manager/explorer/application::restaurantrooms.restaurantrooms/relation-list/restaurant?_limit=20&_start=0`
        axios.get(api, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((response) => {
                const details = response.data;
                setRestuarantLists(details);
            })
            .catch((error) => {
                console.error('restuarantList', error);
            })
    }

    function arrangeData() {
        let data = {};
        let count = 0;
        const dateString = new Date();
        const dateObject = dateString.toISOString();
        Object.entries(rooms).map((outsideData) => (
            Array.isArray(outsideData[1]) &&
            Object.entries(outsideData[1]).map((insideData, idx) => (
                count = count + 1,
                Object.entries(insideData[1]).map((et) => {
                    if (idx === 0) {
                        data['id'] = outsideData[1].length + 1
                        data['name'] = ''
                        data['type'] = 'indoor'
                        data['booked'] = false
                        data['chairCount'] = 6
                        data['booked_from'] = dateObject
                        data['booked_to'] = dateObject
                    }
                }
                )
            ))
        ));
        return data;
    }

    const handleRestaurantChange = (e) => {
        const selectedRestaurantName = e.target.value;
        const selectedRestaurant = restuarantLists.find(restaurant => restaurant.name === selectedRestaurantName);
        if (selectedRestaurant) {
            setRestuarant(selectedRestaurant);
        }
    };

    return (
        <>
            <TableModal
                closeModal={() => setEditModal(false)}
                visible={editModal}
                tableDetails={tableDetails}
                roomDetails={rooms}
                restuarantList={restuarantLists}
            />
            <Box sx={{ overflow: 'hidden' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button onClick={() => props.backMenu()} color="primary" variant="outlined" startIcon={<AiOutlineArrowLeft />}>Back</Button>
                    <Button
                        // onClick={() => { setEditModal(true); setTableDetails(headingData) }}
                        onClick={() => alert('Inprogress')}
                        color="primary" variant="contained">Add New Rooms</Button>

                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 30 }}>
                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <ListItemText primary="Name : " />
                        <ListItemText primary={roomDetails.name} />
                    </div>
                    <div>
                        <div className='searchRestuarantBox'>
                            <ListItemText primary="Restuarants" />
                            <select
                                id='auth-table-restuarant'
                                value={restuarant.name}
                                className='selectBox'
                                autoFocus
                                onChange={handleRestaurantChange}
                            >
                                {
                                    restuarantLists.map((restuarant) => (
                                        <option value={restuarant.name} >{restuarant.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div>
                    <InputLabel htmlFor='auth-login-email'>Rooms</InputLabel>
                    <Paper sx={{ overflow: 'hidden', marginTop: 2, borderWidth: 2, borderColor: 'blanchedalmond' }}>
                        <TableContainer>
                            {rooms != null ? <Table>
                                <TableHead>
                                    <TableRow>
                                        {
                                            Object.entries(rooms).map((outsideData) => (
                                                Array.isArray(outsideData[1]) &&
                                                Object.entries(outsideData[1]).map((insideData, idx) => (
                                                    Object.entries(insideData[1]).map((et) => (
                                                        idx === 0 && <TableCell className='heading'>{et[0]}</TableCell>
                                                    )
                                                    )
                                                ))
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Object.entries(rooms).map((outsideData) => (
                                            Array.isArray(outsideData[1]) &&
                                            Object.entries(outsideData[1]).map((insideData, idx) => (
                                                <TableRow key={idx}>
                                                    {Object.entries(insideData[1]).map((et) => (
                                                        <TableCell>{String(et[1])}</TableCell>
                                                    ))}
                                                    <TableCell
                                                        // onClick={() => { setEditModal(true); setTableDetails(insideData[1]) }}
                                                        onClick={() => alert('Inprogress')}
                                                    ><BsFillPencilFill /></TableCell>
                                                    <TableCell><ImBin /></TableCell>
                                                </TableRow>
                                            ))
                                        ))
                                    }
                                </TableBody>
                            </Table> : <div>Empty room Create One</div>}
                        </TableContainer>
                    </Paper>
                </div>

            </Box>
        </>
    )
}


export function TableModal(props) {
    const [table, setTable] = useState({});
    const [restuarant, setRestuarant] = useState('');

    useEffect(() => {
        props.tableDetails ? setTable(props.tableDetails) : setTable({});
    },);

    function mainData(key, value) {
        switch (key) {
            case 'id':
                return (
                    <div>
                        <InputLabel htmlFor={`auth-table-${key}`} className='heading'>{key}</InputLabel>
                        <OutlinedInput
                            value={value}
                            id={`auth-table-${key}`}
                            type='text'
                        />
                    </div>
                )
            case 'name':
                return (
                    <div>
                        <InputLabel htmlFor={`auth-table-${key}`} className='heading'>{key}</InputLabel>
                        <OutlinedInput
                            value={value}
                            id={`auth-table-${key}`}
                            type='text'
                            onChange={(event) => setTable(table[key] = event.target.value)}
                        />
                    </div>
                )
            case 'booked':
                return (
                    <div>
                        <InputLabel htmlFor={`auth-table-${key}`} className='heading'>{key}</InputLabel>
                        <Switch
                            checked={value}
                            onChange={(event) => setTable(table[key] = Boolean(!value))}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                )
            case 'type':
                return (
                    <div>
                        <InputLabel htmlFor={`auth-table-${key}`} className='heading'>{key}</InputLabel>
                        <select
                            value={value}
                            onChange={(event) => setTable(table[key] = event.target.value)}>
                            <option value='indoor'>Indoor</option>
                            <option value='outdoor'>Outdoor</option>
                        </select>
                    </div>
                )
            default:
                return (
                    <></>
                )
        }
    }

    const setTheData = (data) => {
        const desiredIndex = 0; // Replace with the index where you want to replace the data
        const room = props.roomDetails;

        if (desiredIndex >= 0 && desiredIndex < room.tables.length) {
            const updatedTables = room.tables.map((table, index) => {
                if (index === desiredIndex) {
                    return data;
                } else {
                    return table;
                }
            });

            const updatedRoom = { ...room, tables: updatedTables };
            // console.log('upddddd', updatedRoom)
        } else {
            console.error("Invalid desired index");
        }

    }

    const handleRestaurantChange = (e) => {   //tis function we are using both table and modal
        const selectedRestaurantName = e.target.value;
        const selectedRestaurant = props.restuarantList.find(restaurant => restaurant.name === selectedRestaurantName);
        if (selectedRestaurant) {
            setRestuarant(selectedRestaurant);
        }
    };

    return (
        <Modal onClose={() => props.closeModal()} open={props.visible}
            style={{ display: 'flex', justifyContent: 'center', alignSelf: 'center', position: 'absolute' }}>
            <div style={{ backgroundColor: 'white', padding: '1%', width: '50%', height: '20%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', }}>
                <div>
                    {Object.entries(table).map(([key, value], idx) => (
                        <div key={idx} >
                            {mainData(key, value)}
                        </div>
                    ))}
                </div>
                <div>
                    <InputLabel htmlFor='auth-table-restuarant' className='heading'>Restuarant</InputLabel>
                    <select
                        value={restuarant.name}
                        onChange={handleRestaurantChange}
                    >
                        {
                            props.restuarantList.map((restuarant) => (
                                <option value={restuarant.name} >{restuarant.name}</option>
                            ))
                        }
                    </select>
                </div>
                <Button onClick={() => setTheData(table)} color="primary" variant="outlined">Save</Button>
            </div>
        </Modal >
    )
}
